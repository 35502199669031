// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicCaseStudiesWraper-module--__wab_img-spacer--7o9Xd";
export var button__nDn6F = "PlasmicCaseStudiesWraper-module--button__nDn6F--yEqSs";
export var freeBox = "PlasmicCaseStudiesWraper-module--freeBox--r6z6w";
export var h3__qyDcu = "PlasmicCaseStudiesWraper-module--h3__qyDcu--nzSGK";
export var img__loE34 = "PlasmicCaseStudiesWraper-module--img__loE34--N9TN7";
export var root = "PlasmicCaseStudiesWraper-module--root--K79Cd";
export var svg__hwblq = "PlasmicCaseStudiesWraper-module--svg__hwblq--CvQR-";
export var svg__zfpQx = "PlasmicCaseStudiesWraper-module--svg__zfpQx--QGSPb";
export var text__oqtlw = "PlasmicCaseStudiesWraper-module--text__oqtlw--z6P3A";
export var text__wx4R0 = "PlasmicCaseStudiesWraper-module--text__wx4R0--uFaI5";